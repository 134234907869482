<template>
  <div v-if="item">
    <router-link v-if="item.route" :to="`${item.route}`">
      <div class="stats_chart">
        <div class="icon">
          <svg
            width="71"
            height="71"
            viewBox="0 0 71 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.6939 3.15092C55.5582 4.51502 68.9343 20.1027 67.5702 37.967C66.2061 55.8313 50.6184 69.2073 32.7541 67.8432C14.8898 66.4791 1.51378 50.8914 2.87787 33.0271C4.24197 15.1629 19.8296 1.78683 37.6939 3.15092ZM33.194 62.0825C47.8767 63.2037 60.6883 52.2098 61.8095 37.5271C62.9306 22.8444 51.9368 10.0328 37.254 8.91162C22.5713 7.79046 9.75972 18.7843 8.63857 33.467C7.51741 48.1498 18.5112 60.9614 33.194 62.0825Z"
              fill="#7EE5DF"
              fill-opacity="0.08"
            />
            <path
              d="M37.3189 5.69619C37.432 4.1048 36.232 2.70956 34.6369 2.73846C30.5863 2.81185 26.5765 3.64354 22.8176 5.19945C18.1669 7.12447 14.028 10.1053 10.7282 13.906C7.42842 17.7068 5.05832 22.2232 3.80538 27.0981C2.55244 31.973 2.45104 37.0726 3.5092 41.9934C4.56735 46.9142 6.75604 51.5213 9.90212 55.4503C13.0482 59.3792 17.0654 62.5222 21.6358 64.6305C26.2063 66.7389 31.2046 67.7549 36.2355 67.598C40.3017 67.4712 44.299 66.5811 48.0217 64.983C49.4877 64.3536 50.0081 62.5884 49.2509 61.1842C48.4936 59.7799 46.7458 59.2714 45.2679 59.8724C42.3442 61.0612 39.2253 61.7245 36.0554 61.8233C31.9205 61.9523 27.8124 61.1173 24.0559 59.3844C20.2994 57.6515 16.9977 55.0683 14.4119 51.8391C11.8261 48.6099 10.0273 44.8232 9.15755 40.7788C8.28784 36.7343 8.37119 32.543 9.40098 28.5363C10.4308 24.5296 12.3788 20.8175 15.0909 17.6936C17.803 14.5698 21.2048 12.1199 25.0272 10.5377C27.9575 9.32476 31.0733 8.64731 34.2272 8.52926C35.8215 8.46958 37.2059 7.28758 37.3189 5.69619Z"
              :fill="`url(#paint${color.id})`"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M38.3198 27.9923C38.3198 27.7747 38.4062 27.5661 38.56 27.4122C38.7139 27.2584 38.9225 27.1719 39.1401 27.1719H45.7028C45.9204 27.1719 46.129 27.2584 46.2829 27.4122C46.4367 27.5661 46.5231 27.7747 46.5231 27.9923V34.555C46.5231 34.7725 46.4367 34.9812 46.2829 35.135C46.129 35.2889 45.9204 35.3753 45.7028 35.3753C45.4852 35.3753 45.2766 35.2889 45.1227 35.135C44.9689 34.9812 44.8825 34.7725 44.8825 34.555V30.2892L38.9547 37.5361C38.8821 37.6246 38.7918 37.697 38.6896 37.7486C38.5874 37.8002 38.4755 37.8298 38.3611 37.8355C38.2467 37.8412 38.1325 37.8229 38.0256 37.7818C37.9187 37.7407 37.8216 37.6777 37.7406 37.5968L33.4962 33.3524L27.4979 41.6C27.3667 41.7669 27.1759 41.8764 26.9656 41.9055C26.7554 41.9346 26.542 41.8811 26.3704 41.7562C26.1987 41.6313 26.0822 41.4447 26.0452 41.2357C26.0083 41.0267 26.0538 40.8115 26.1722 40.6353L32.7349 31.6116C32.8046 31.5156 32.8942 31.4359 32.9976 31.3779C33.1011 31.32 33.2158 31.2851 33.334 31.2757C33.4522 31.2664 33.5711 31.2828 33.6823 31.3237C33.7936 31.3647 33.8947 31.4294 33.9785 31.5132L38.2591 35.7953L43.9719 28.8126H39.1401C38.9225 28.8126 38.7139 28.7262 38.56 28.5723C38.4062 28.4185 38.3198 28.2098 38.3198 27.9923Z"
              :fill="color.colors.first"
            />
            <defs>
              <linearGradient
                :id="`paint${color.id}`"
                x1="32.7205"
                y1="3.05613"
                x2="60.9941"
                y2="50.9482"
                gradientUnits="userSpaceOnUse"
              >
                <stop :stop-color="color.colors.first" />
                <stop offset="1" stop-color="#7EE5DF" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="content">
          <span class="name">{{ item.title }}</span>
          <span class="value">
            {{ Math.floor(item.count) }}
          </span>
        </div>
      </div>
    </router-link>
    <a role="button" v-else>
      <div class="stats_chart">
        <div class="icon">
          <svg
            width="71"
            height="71"
            viewBox="0 0 71 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.6939 3.15092C55.5582 4.51502 68.9343 20.1027 67.5702 37.967C66.2061 55.8313 50.6184 69.2073 32.7541 67.8432C14.8898 66.4791 1.51378 50.8914 2.87787 33.0271C4.24197 15.1629 19.8296 1.78683 37.6939 3.15092ZM33.194 62.0825C47.8767 63.2037 60.6883 52.2098 61.8095 37.5271C62.9306 22.8444 51.9368 10.0328 37.254 8.91162C22.5713 7.79046 9.75972 18.7843 8.63857 33.467C7.51741 48.1498 18.5112 60.9614 33.194 62.0825Z"
              fill="#7EE5DF"
              fill-opacity="0.08"
            />
            <path
              d="M37.3189 5.69619C37.432 4.1048 36.232 2.70956 34.6369 2.73846C30.5863 2.81185 26.5765 3.64354 22.8176 5.19945C18.1669 7.12447 14.028 10.1053 10.7282 13.906C7.42842 17.7068 5.05832 22.2232 3.80538 27.0981C2.55244 31.973 2.45104 37.0726 3.5092 41.9934C4.56735 46.9142 6.75604 51.5213 9.90212 55.4503C13.0482 59.3792 17.0654 62.5222 21.6358 64.6305C26.2063 66.7389 31.2046 67.7549 36.2355 67.598C40.3017 67.4712 44.299 66.5811 48.0217 64.983C49.4877 64.3536 50.0081 62.5884 49.2509 61.1842C48.4936 59.7799 46.7458 59.2714 45.2679 59.8724C42.3442 61.0612 39.2253 61.7245 36.0554 61.8233C31.9205 61.9523 27.8124 61.1173 24.0559 59.3844C20.2994 57.6515 16.9977 55.0683 14.4119 51.8391C11.8261 48.6099 10.0273 44.8232 9.15755 40.7788C8.28784 36.7343 8.37119 32.543 9.40098 28.5363C10.4308 24.5296 12.3788 20.8175 15.0909 17.6936C17.803 14.5698 21.2048 12.1199 25.0272 10.5377C27.9575 9.32476 31.0733 8.64731 34.2272 8.52926C35.8215 8.46958 37.2059 7.28758 37.3189 5.69619Z"
              :fill="`url(#paint${color.id})`"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M38.3198 27.9923C38.3198 27.7747 38.4062 27.5661 38.56 27.4122C38.7139 27.2584 38.9225 27.1719 39.1401 27.1719H45.7028C45.9204 27.1719 46.129 27.2584 46.2829 27.4122C46.4367 27.5661 46.5231 27.7747 46.5231 27.9923V34.555C46.5231 34.7725 46.4367 34.9812 46.2829 35.135C46.129 35.2889 45.9204 35.3753 45.7028 35.3753C45.4852 35.3753 45.2766 35.2889 45.1227 35.135C44.9689 34.9812 44.8825 34.7725 44.8825 34.555V30.2892L38.9547 37.5361C38.8821 37.6246 38.7918 37.697 38.6896 37.7486C38.5874 37.8002 38.4755 37.8298 38.3611 37.8355C38.2467 37.8412 38.1325 37.8229 38.0256 37.7818C37.9187 37.7407 37.8216 37.6777 37.7406 37.5968L33.4962 33.3524L27.4979 41.6C27.3667 41.7669 27.1759 41.8764 26.9656 41.9055C26.7554 41.9346 26.542 41.8811 26.3704 41.7562C26.1987 41.6313 26.0822 41.4447 26.0452 41.2357C26.0083 41.0267 26.0538 40.8115 26.1722 40.6353L32.7349 31.6116C32.8046 31.5156 32.8942 31.4359 32.9976 31.3779C33.1011 31.32 33.2158 31.2851 33.334 31.2757C33.4522 31.2664 33.5711 31.2828 33.6823 31.3237C33.7936 31.3647 33.8947 31.4294 33.9785 31.5132L38.2591 35.7953L43.9719 28.8126H39.1401C38.9225 28.8126 38.7139 28.7262 38.56 28.5723C38.4062 28.4185 38.3198 28.2098 38.3198 27.9923Z"
              :fill="color.colors.first"
            />
            <defs>
              <linearGradient
                :id="`paint${color.id}`"
                x1="32.7205"
                y1="3.05613"
                x2="60.9941"
                y2="50.9482"
                gradientUnits="userSpaceOnUse"
              >
                <stop :stop-color="color.colors.first" />
                <stop offset="1" stop-color="#7EE5DF" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="content">
          <span class="name">{{ item.title }}</span>
          <span class="value">
            {{ Math.floor(item.count) }}
          </span>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "StatisticsCard",

  props: ["item", "color"],
};
</script>
