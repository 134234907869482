<template>
  <div>
    <Breadcrumb :items="items" />
    <Loader v-if="loading && editMode"></Loader>
    <div v-else>
      <noData v-if="noData" />
      <div class="form countryForm custom_card" v-else>
        <ValidationObserver ref="formCoupon" @submit.prevent="submit">
          <div class="row">
            <!-- Start:: code -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.code')"
              class="col-lg-4"
              rules="required|min:2|max:250"
            >
              <base-input
                :name="$t('labels.code')"
                type="text"
                :placeholder="$t('labels.code')"
                v-model="data.code"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: code -->
            <!-- Start:: user_type -->
            <!-- <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.user_type')"
              class="col-lg-4"
              rules="required"
            >
              <base-select-input
                v-model="data.user_type"
                getUrl="continents"
                :static="true"
                :name="$t('labels.user_type')"
                :staticItems="userTypes"
                :placeholder="$t('labels.user_type')"
              ></base-select-input>

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider> -->
            <!-- End:: user_type -->
            <!-- Start:: discount_type -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.discount_type')"
              class="col-lg-4"
              rules="required|min:2|max:250"
            >
              <base-select-input
                :name="$t('labels.discount_type')"
                v-model="data.discount_type"
                :static="true"
                :staticItems="discountTypes"
                :placeholder="$t('labels.discount_type')"
              ></base-select-input>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: discount_type -->

            <!-- Start:: DiscountAmount -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="`${
                data.discount_type ? data.discount_type?.name : $t('select')
              } ${$t('discound')}`"
              class="col-lg-4"
              :rules="`required|min_value:1|${
                data.discount_type?.id == 'percentage' ? 'max_value:100' : ''
              }`"
            >
              <base-input
                :name="`${
                  data.discount_type ? data.discount_type.name : $t('select')
                } ${$t('discound')}`"
                type="number"
                @keypress="preventMinus"
                :placeholder="`${
                  data.discount_type ? data.discount_type.name : $t('select')
                } ${$t('discound')}`"
                v-model.trim="data.amount"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: DiscountAmount -->
            <!-- Start:: UsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.usage_times')"
              class="col-lg-4"
              rules="required|min_value:1"
            >
              <base-input
                :name="$t('labels.usage_times')"
                type="number"
                @keypress="preventMinus"
                :placeholder="$t('labels.usage_times')"
                v-model.number="data.usage_count"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UsageTimes -->
            <!-- Start:: UserUsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.user_usage_count')"
              class="col-lg-4"
              :rules="`required|min_value:1|${
                data.usage_count ? `max_value:${data.usage_count}` : ''
              }`"
            >
              <base-input
                :disabled="!data.usage_count"
                :name="$t('labels.user_usage_count')"
                type="number"
                @keypress="preventMinus"
                :placeholder="
                  !data.usage_count
                    ? $t('select') +
                      ' ' +
                      $t('labels.usage_times') +
                      ' ' +
                      $t('firstly')
                    : $t('labels.user_usage_count')
                "
                v-model.number="data.user_usage_count"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <!-- End:: UserUsageTimes -->
            <!-- Start:: UserUsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="`${
                data.discount_type
                  ? $t('labels.max_amount', { name: data.discount_type.name })
                  : $t('labels.max_amount', { name: $t('monyAmount') })
              }`"
              class="col-lg-4"
              :rules="`required|min_value:1|${
                data.amount ? `max_value:${data.amount}` : ''
              }`"
            >
              <base-input
                :name="`${
                  data.discount_type
                    ? $t('labels.max_amount', { name: data.discount_type.name })
                    : $t('labels.max_amount', { name: $t('monyAmount') })
                }`"
                type="number"
                @keypress="preventMinus"
                :placeholder="`${
                  data.discount_type
                    ? $t('labels.max_amount', { name: data.discount_type.name })
                    : $t('labels.max_amount', { name: $t('monyAmount') })
                }`"
                v-model.number="data.max_amount"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UserUsageTimes -->
            <!-- Start::  Start At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at')"
                type="date"
                :allowedDate="new Date()"
                v-model="data.start_at"
                :placeholder="$t('labels.start_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  Start At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at_time')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at_time')"
                type="time"
                v-model="data.start_time"
                :placeholder="$t('labels.start_at_time')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  Start At -->
            <!-- Start::  end At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at')"
                type="date"
                v-model="data.end_at"
                :allowedDate="data.start_at ? data.start_at : null"
                :placeholder="$t('labels.end_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  end At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at_time')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at_time')"
                type="time"
                v-model="data.end_time"
                :placeholder="$t('labels.end_at_time')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  end At -->

            <!-- Start:: Checks -->
            <div class="col-lg-12 py-0">
              <div class="divider">
                <h4>{{ $t("labels.status") }}</h4>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--dark"
                />
              </div>
            </div>
            <v-checkbox
              v-model="data.is_active"
              :label="$t('labels.is_active')"
            ></v-checkbox>
          </div>

          <div class="button_wrapper center">
            <v-btn
              type="submit"
              @click="submit"
              :disabled="loading"
              :loading="loading"
            >
              {{ $t("submit") }}
            </v-btn>
          </div>
          <!-- <base-button type="submit" @click="submit" class="center"></base-button> -->
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// import MapWrite from "../../../../components/Maps/MapWrite.vue";

export default {
  // components: { MapWrite },
  props: ["id"],

  data() {
    return {
      noData: false,
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.coupons.title"),
          disabled: false,
          href: "/coupons/show-all",
        },
        {
          text: this.$t(`breadcrumb.coupons.${this.id ? "edit" : "add"}`),
          disabled: true,
          href: "",
        },
      ],
      // Loading

      loading: false,

      data: {
        code: null,
        start_at: null,
        start_time: null,
        end_at: null,
        max_amount: null,
        end_time: null,
        usage_count: null,
        user_usage_count: null,
        discount_type: null,
        user_type: null,
        amount: null,
        is_active: false,
      },

      discountTypes: [
        {
          id: "amount",
          name: this.$t("monyAmount"),
        },
        {
          id: "percentage",
          name: this.$t("percentage", { name: " " }),
        },
      ],
    };
  },

  methods: {
    getEditData() {
      this.noData = false;
      this.loading = true;
      this.axios({
        method: "GET",
        url: `coupon/${this.id}`,
      })
        .then((res) => {
          this.loading = false;
          this.data = {
            code: res.data.data.code,
            discount_type: this.discountTypes.find((item) => {
              return item.id == res.data.data.descount_type;
            }),
            user_type: this.userTypes.find((item) => {
              return item.id == res.data.data.user_type;
            }),
            package: res.data.data.package,
            amount: res.data.data.amount,
            max_amount: res.data.data.max_amount,
            usage_count: res.data.data.usage_count,
            user_usage_count: res.data.data.user_usage_count,
            start_at: new Date(res.data.data.start_date).toLocaleDateString(
              "en-CA"
            ),
            end_at: new Date(res.data.data.end_date).toLocaleDateString(
              "en-CA"
            ),
            start_time: new Date(
              res.data.data.start_date
            ).toLocaleTimeString("en-US", { hour12: false }),
            end_time: new Date(
              res.data.data.end_date
            ).toLocaleTimeString("en-US", { hour12: false }),

            // Checks
            is_active: res.data.data.is_active,
            is_city_to_city_active: res.data.data.is_city_to_city_active,
            is_trip_active: res.data.data.is_trip_active,
            is_package_delivering_active:
              res.data.data.is_package_delivering_active,
          };
        })
        .catch((err) => {
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
          this.noData = true;
          this.loading = false;
        });
    },

    submit() {
      this.$refs.formCoupon.validate().then((d) => {
        if (d) {
          this.loading = true;
          const data = new FormData();
          data.append(
            "start_date",
            `${this.$moment(this.data.start_at).format("YYYY-MM-DD")} ${
              this.data.start_time
            }`
          );
          data.append(
            "end_date",
            `${this.$moment(this.data.end_at).format("YYYY-MM-DD")} ${
              this.data.end_time
            }`
          );
          data.append("usage_count", this.data.usage_count);
          data.append("user_usage_count", this.data.user_usage_count);
          data.append("user_type", "rider");
          data.append("descount_type", this.data.discount_type?.id);
          data.append("code", this.data.code);
          data.append("max_amount", this.data.max_amount);
          data.append("amount", this.data.amount);
          data.append("is_active", +this.data.is_active);

          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `coupon/${this.id}`;
          } else {
            url = "coupon";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");

          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.loading = false;
              this.$router.push("/coupons/show-all");
            })
            .catch((err) => {
              this.loading = false;
              this.$iziToast.error({
                title: this.$t("error"),
                message:
                  err.response.data.message ?? err.response.data.messages,
              });
            });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },
    // allowedDate(val) {
    //   console.log(val);
    //   return new Date() - 1;
    // },
  },

  computed: {
    editMode() {
      return !!this.id;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    if (this.id) {
      this.getEditData();
    }
  },
};
</script>
