<template>
  <Loader v-if="loading && editMode"></Loader>
  <div v-else>
    <Breadcrumb :items="items" />
    <noData v-if="noData" />
    <div class="form countryForm custom_card fadeIn" v-else>
      <ValidationObserver ref="formModels" @submit.prevent="submit">
        <div class="row">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('labels.model_image')"
            class="d-block my-auto"
            style="width: 100%;"
            :rules="editMode ? '' : 'required'"
          >
            <v-file-input
              @change="uploadImage($event)"
              v-model="data.mainMedia"
              class="d-none"
              :name="$t('labels.model_image')"
              accept="image/*,|size:2048"
              label=""
              id="banner-create-image-main"
            ></v-file-input>

            <label
              for="banner-create-image-main"
              class="upload-image-label d-block d-flex justify-content-center align-items-center mx-auto text-center border rounded-xl overflow-hidden"
            >
              <v-img
                v-if="data.preview"
                class="d-flex"
                cover
                :src="data.preview"
                :aspect-ratio="3.4"
              />
              <img
                v-else
                class="d-flex img-fluid mx-auto"
                src="@/assets/media/images/placeholder.png"
              />

              <span class="error--text d-block">
                {{ errors[0] }}
              </span>
            </label>
          </validation-provider>
          <!-- Start Name -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.name') + $t('inArabic')"
            class="col-lg-6"
            rules="required|min:2|max:250"
          >
            <base-input
              @keypress="isArabic"
              :name="$t('labels.name') + $t('inArabic')"
              type="text"
              :placeholder="$t('labels.name') + $t('inArabic')"
              v-model="data.ar.name"
            />
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.name') + $t('inEnglish')"
            class="col-lg-6"
            rules="required|min:2|max:250"
          >
            <base-input
              @keypress="isEnglish"
              :name="$t('labels.name') + $t('inEnglish')"
              type="text"
              :placeholder="$t('labels.name') + $t('inEnglish')"
              v-model="data.en.name"
            />
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <!-- End Name -->

          <!-- start:: Brand -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.brand')"
            class="col-lg-6"
            rules="required"
          >
            <base-select-input
              :name="$t('labels.brand')"
              v-model="data.brand"
              getUrl="vehicle_brand_not_paginated"
              :placeholder="$t('labels.brand')"
            ></base-select-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <!-- End:: Brand -->
        </div>
        <div class="button_wrapper center">
          <v-btn
            type="submit"
            @click="submit"
            :disabled="btnLoading"
            :loading="btnLoading"
          >
            {{ $t("submit") }}
          </v-btn>
        </div>
        <!-- <base-button type="submit" @click="submit" class="center"></base-button> -->
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],

  data() {
    return {
      noData: false,
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.vehicle_models.title"),
          disabled: false,
          href: "/vehicle_models/show-all",
        },
        {
          text: this.$t(
            `breadcrumb.vehicle_models.${this.id ? "edit" : "add"}`
          ),
          disabled: true,
          href: "",
        },
      ],
      // Loading
      loading: false,
      btnLoading: false,

      data: {
        mainMedia: null,
        preview: null,

        image: null,
        mainPreviewUrl: null,
        ar: {
          name: null,
          desc: null,
        },
        en: {
          name: null,
          desc: null,
        },
        is_active: true,
        brand: null,
      },
    };
  },

  methods: {
    inputChanged(link) {
      this.data.image.title = link;
    },

    getEditData() {
      this.noData = false;
      if (this.editMode) {
        this.loading = true;
        this.axios({
          method: "GET",
          url: `vehicle_model/${this.id}`,
        })
          .then((res) => {
            this.loading = false;
            this.data = {
              preview: res.data.data.image,
              ar: res.data.data.ar,
              en: res.data.data.en,
              // is_active: res.data.data.is_active,
              brand: {
                id: res.data.data.vechile_brand?.id,
                name: res.data.data.vechile_brand?.name,
              },
            };
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("error"),
              message:
                err.response?.data.message ?? err.response?.data.messages,
            });
            this.noData = true;

            this.loading = false;
          });
      }
    },

    submit() {
      this.$refs.formModels.validate().then((d) => {
        if (d) {
          this.btnLoading = true;

          const data = new FormData();
          if (this.id) {
            if (this.data.image) {
              data.append("image", this.data.image);
            }
          } else {
            data.append("image", this.data.image);
          }

          data.append("ar[name]", this.data.ar.name);

          data.append("en[name]", this.data.en.name);
          data.append("is_active", +this.data.is_active); // remove later
          data.append("vehicle_brand_id", this.data.brand.id);

          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `vehicle_model/${this.id}`;
          } else {
            url = "vehicle_model";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");

          // =============== Start:: Add - Edit ===============
          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.$router.push("/vehicle_models/show-all");
              this.btnLoading = false;
            })
            .catch((err) => {
              this.$iziToast.error({
                title: this.$t("error"),
                message: err.response.data.message,
              });
              this.btnLoading = false;
            });

          // =============== End:: Add ===============
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },
    uploadImage(event) {
      if (event) {
        this.btnLoading = true;

        this.data.preview = URL.createObjectURL(event);

        const data = new FormData();
        data.append("file", event);
        data.append("attachment_type", "image");
        data.append("model", "vehicle_models");

        this.axios({
          method: "POST",
          url: "attachments",
          data: data,
        })
          .then((res) => {
            this.data.image = res.data.data;
            // this.$emit('inputChanged', res.data.data)
            this.btnLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("validation.error"),
              message: err.response.data.message,
            });

            this.btnLoading = false;
          });
      }
    },
  },

  computed: {
    editMode() {
      return !!this.id;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    this.getEditData();
  },
};
</script>
