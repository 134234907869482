<template>
  <div class="countries_all">
    <template>
      <!-- <the-filter :inputs="inputs" /> -->
      <!-- @filterFired="filterFired" -->
      <Breadcrumb
        :canAddNew="!canAdd"
        :items="items"
        search_route="/coupons/add"
        :search_title="$t('breadcrumb.coupons.add')"
        icon="fa-plus"
      />
      <!-- :custom-filter="helper_filterSearch" -->

      <main>
        <v-data-table
          class="thumb strip"
          :headers="headers"
          :items="rows"
          :search="search"
          :loading="loading"
          :expanded.sync="expanded"
          :loading-text="$t('table.loadingData')"
          item-key="id"
          :items-per-page="paginations.items_per_page"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- Expanded -->
          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length" class="expandDate fadeIn">
              <span class="expandTitle">المزيد من التفاصيل</span>
              <ul>
                <li v-for="(value, key) of expandData" :key="key">
                  <span class="key">{{ key }}</span>
                  <span class="value">
                    <template v-if="value != null && value != 'null'">
                      {{ value }}
                    </template>
                    <template v-else-if="value == null || value == 'null'">
                      {{ $t("table.noData") }}
                    </template>
                  </span>
                </li>
              </ul>
            </td>
          </template>
          <template v-slot:[`item.extendIcon`]="{ item }">
            <div class="_actions">
              <v-icon class="show" small @click="clickRow(item)">
                fas fa-angle-double-down
              </v-icon>
            </div>
          </template>

          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ new Date(item.created_at).toLocaleDateString() }}
          </template>
          <template v-slot:[`item.image`]="{ item, index }">
            <img
              class="image"
              :src="item.image"
              @error="imageError(index)"
              @click="show_model_1(item.image)"
              v-if="item.image"
            />
            <span class="redColor fontBold" v-else>{{ $t("notFound") }}</span>
          </template>

          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <span
              class="status"
              :class="item.is_active ? 'active' : 'inactive'"
            >
              {{ helper_boolToText(item.is_active) }}
            </span>
          </template>
          <template v-slot:[`item.user_type`]="{ item }">
            {{ $t(item.user_type) }}
          </template>
          <template v-slot:[`item.descount_type`]="{ item }">
            {{ $t(item.descount_type, { name: "" }) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <v-icon
                class="edit"
                v-if="!canEdit"
                small
                @click="editItem(item)"
              >
                fal fa-edit
              </v-icon>
              <deleter
                v-if="!canDelete"
                :items="rows"
                name="coupon"
                :id="item.id"
                @refresh="rows = $event"
              />
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <h3 class="table-title title">
              {{ $t("breadcrumb.coupons.title") }}
              <span class="total">({{ total }})</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    far fa-info-circle
                  </v-icon>
                </template>
                <span> {{ $t("breadcrumb.coupons.all") }}</span>
              </v-tooltip>
            </h3>
            <!-- Delete dialog -->

            <!-- Add & Delete -->

            <v-row>
              <v-col cols="12" sm="8"></v-col>
            </v-row>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
        <!-- Start Pagination -->
        <template>
          <div
            class="pagination_container text-center mb-5 d-flex justify-content-end"
          >
            <v-pagination
              color="primary"
              v-model="paginations.current_page"
              :length="paginations.last_page"
              :total-visible="5"
              @input="fetchData($event)"
            ></v-pagination>
          </div>
        </template>
        <!-- End Pagination -->
      </main>

      <!-- Start Image_Model -->
      <base-model
        @close="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            class="img-fluid"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.coupons.title"),
          disabled: false,
          href: "/coupons/show-all",
        },
        {
          text: this.$t("breadcrumb.coupons.all"),
          disabled: true,
          href: "",
        },
      ],
      // ========== headers
      headers: [
        // {
        //   text: this.$t("labels.mainImage"),
        //   align: "center",
        //   value: "image",
        //   sortable: false,
        // },
        {
          text: "#",
          value: "index",
          align: "center",
        },
        {
          text: this.$t("labels.code"),
          value: "code",
          align: "center",
        },
        {
          text: this.$t("labels.discound"),
          value: "amount",
          align: "center",
        },
        {
          text: this.$t("labels.descount_type"),
          value: "descount_type",
          align: "center",
        },

        {
          text: this.$t("labels.user_type"),
          value: "user_type",
          align: "center",
        },

        {
          text: this.$t("labels.start_at"),
          value: "start_date",
          align: "center",
        },
        {
          text: this.$t("labels.end_at"),
          value: "end_date",
          align: "center",
        },

        {
          text: this.$t("labels.activation"),
          align: "center",
          value: "is_active",
          sortable: false,
        },
        {
          text: this.$t("labels.extra"),
          value: "extendIcon",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.control"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      rows: [],
      expandData: [],
      expanded: [],
      search: null,
    };
  },

  methods: {
    setRows() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: `coupon`,
        params: { page: this.paginations.current_page },
      })
        .then((res) => {
          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;
          this.total = res.data.meta?.total;
          this.rows = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          // console.log(err.response.data.messages);
          let message =
            err.response?.data.message ?? err.response?.data.messages;
          this.$iziToast.error({
            title: this.$t("validation.error"),
            message: message,
          });
          this.loading = false;
        });
    },
    imageError(index) {
      this.rows[
        index
      ].image = require("@/assets/media/images/Icons/no-data.gif");
    },
    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: `/coupons/add` });
    },
    showItem(item) {
      this.$router.push({ path: `/coupons/show/` + item.id });
    },
    editItem(item) {
      this.$router.push({ path: `/coupons/edit/` + item.id });
    },
    mapItem(data) {
      this.expandData = {
        [this.$t("labels.usage_count")]: this.$t(data.usage_count),
        [this.$t("labels.user_usage_count")]: this.$t(data.user_usage_count),
        [this.$t("labels.number_of_usages")]: this.$t(data.number_of_usages),
        [this.$t("labels.created_at")]: new Date(
          data.created_at
        ).toLocaleDateString(),
      };
    },
    clickRow(rowData) {
      if (this.noExpand) return;
      const indexExpanded = this.expanded.findIndex((i) => i === rowData);
      if (indexExpanded > -1) {
        // Exisit
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded = [];
        this.expanded.push(rowData);
        this.mapItem(rowData);
        // this.expandData = rowData
      }
    },
    fetchData(e) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { page: e }),
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.setRows();
    },
  },
  watch: {
    [`$route`]() {
      if (this.$route.query.page) {
        this.paginations.current_page = +this.$route.query.page;
        this.setRows();
      } else {
        this.paginations.current_page = 1;
        this.setRows();
      }
    },
  },
  created() {
    this.canAdd = this.$permission.allowed("coupons/add");
    this.canEdit = this.$permission.allowed("coupons/edit/");
    this.canDelete = this.$permission.allowed("coupons/delete");
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },
};
</script>
