<template>
  <Loader v-if="loading && editMode"></Loader>
  <div v-else>
    <Breadcrumb :items="items" />
    <noData v-if="noData" />
    <div class="form countryForm custom_card fadeIn" v-else>
      <ValidationObserver ref="formBrand" @submit.prevent="submit">
        <div class="row">
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.name') + $t('inArabic')"
            class="col-lg-6"
            rules="required|min:2|max:250"
          >
            <base-input
              @keypress="isArabic"
              :name="$t('labels.name') + $t('inArabic')"
              type="text"
              :placeholder="$t('labels.name') + $t('inArabic')"
              v-model="data.ar.name"
            />
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.name') + $t('inEnglish')"
            class="col-lg-6"
            rules="required|min:2|max:250"
          >
            <base-input
              @keypress="isEnglish"
              :name="$t('labels.name') + $t('inEnglish')"
              type="text"
              :placeholder="$t('labels.name') + $t('inEnglish')"
              v-model="data.en.name"
            />
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <!-- End Name -->

          <div class="col-lg-12 py-0">
            <div class="divider">
              <h4>{{ $t("labels.status") }}</h4>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
          </div>

          <!-- End :: Checks -->

          <!-- Start :: is_active -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.is_active')"
            class="col-6"
            rules="required"
          >
            <div>
              <div class="input-title">
                <h4>{{ $t("labels.is_active") }}</h4>
              </div>
              <v-checkbox
                :name="$t('labels.is_active')"
                class="py-0 px-0"
                v-model="data.is_active"
                :label="$t('labels.is_active')"
              ></v-checkbox>
            </div>
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <!-- End :: is_active -->
        </div>
        <div class="button_wrapper center">
          <v-btn
            type="submit"
            @click="submit"
            :disabled="btnLoading"
            :loading="btnLoading"
          >
            {{ $t("submit") }}
          </v-btn>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: ["id"],

  data() {
    return {
      noData: false,
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.vehicle_brands.title"),
          disabled: false,
          href: "/vehicle_brands/show-all",
        },
        {
          text: this.$t(
            `breadcrumb.vehicle_brands.${this.id ? "edit" : "add"}`
          ),
          disabled: true,
          href: "",
        },
      ],
      // Loading
      loading: false,
      btnLoading: false,

      data: {
        mainMedia: null,
        preview: null,

        image: null,
        mainPreviewUrl: null,
        ar: {
          name: null,
          desc: null,
        },
        en: {
          name: null,
          desc: null,
        },
        is_active: true,
        brand: null,
      },
    };
  },

  methods: {
    ...mapMutations({
      set_breadcrumb: "set_breadcrumb",
    }),

    inputChanged(link) {
      this.data.image.title = link;
    },

    getEditData() {
      this.noData = false;
      if (this.editMode) {
        this.loading = true;
        this.axios({
          method: "GET",
          url: `vehicle_brand/${this.id}`,
        })
          .then((res) => {
            this.loading = false;
            this.data = {
              preview: res.data.data.image,
              ar: res.data.data.ar,
              en: res.data.data.en,
              is_active: res.data.data.is_active,
              // brand: res.data.data.vehicle_brand,
            };
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("error"),
              message:
                err.response?.data.message ?? err.response?.data.messages,
            });
            this.noData = true;

            this.loading = false;
          });
      }
    },

    submit() {
      this.$refs.formBrand.validate().then((d) => {
        if (d) {
          this.btnLoading = true;

          const data = new FormData();
          if (this.id) {
            if (this.data.image) {
              data.append("image", this.data.image);
            }
          } else {
            data.append("image", this.data.image);
          }

          data.append("ar[name]", this.data.ar.name);

          data.append("en[name]", this.data.en.name);
          data.append("is_active", +this.data.is_active);

          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `vehicle_brand/${this.id}`;
          } else {
            url = "vehicle_brand";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");

          // =============== Start:: Add - Edit ===============
          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.$router.push("/vehicle_brands/show-all");
              this.btnLoading = false;
            })
            .catch((err) => {
              this.$iziToast.error({
                title: this.$t("error"),
                message: err.response.data.message,
              });
              this.btnLoading = false;
            });

          // =============== End:: Add ===============
        } else {
          // for (const value of Object.entries(this.$refs.formBrand.errors)) {
          //   if (value[1].length) {
          //     this.$iziToast.error({
          //       timeout: 2000,
          //       message: value[1],
          //       position: "bottomRight",
          //     });
          //   }
          // }
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },
    uploadImage(event) {
      if (event) {
        this.btnLoading = true;

        this.data.preview = URL.createObjectURL(event);

        const data = new FormData();
        data.append("file", event);
        data.append("attachment_type", "image");
        data.append("model", "vehicle_brands");

        this.axios({
          method: "POST",
          url: "attachments",
          data: data,
        })
          .then((res) => {
            this.data.image = res.data.data;
            // this.$emit('inputChanged', res.data.data)
            this.btnLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("validation.error"),
              message: err.response.data.message,
            });

            this.btnLoading = false;
          });
      }
    },
  },

  computed: {
    editMode() {
      return !!this.id;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    this.getEditData();
  },
};
</script>
