<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div v-else>
      <Breadcrumb :items="items" />
      <noData v-if="noData" />
      <div class="form countryForm custom_card" v-else>
        <ValidationObserver ref="formPackage" @submit.prevent="submit">
          <div class="row">
            <!-- Start:: Image -->
            <validation-provider
              v-slot="{ errors }"
              :name="$t('labels.package_image')"
              class="d-block my-auto"
              style="width: 100%;"
              :rules="editMode ? '' : 'required'"
            >
              <v-file-input
                @change="uploadImage($event)"
                v-model="data.image.mainMedia"
                class="d-none"
                :name="$t('labels.package_image')"
                accept="image/*,|size:2048"
                label=""
                id="banner-create-image-main"
              ></v-file-input>
              <label
                for="banner-create-image-main"
                class="upload-image-label d-block d-flex justify-content-center align-items-center mx-auto text-center border rounded-xl overflow-hidden"
              >
                <v-img
                  v-if="data.image.preview"
                  class="d-flex"
                  cover
                  :src="data.image.preview"
                  :aspect-ratio="3.4"
                />
                <img
                  v-else
                  class="d-flex img-fluid mx-auto"
                  src="@/assets/media/images/placeholder.png"
                />

                <span class="error--text d-block">
                  {{ errors[0] }}
                </span>
              </label>
            </validation-provider>
            <!-- End:: Image -->

            <!-- Start:: title -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.title') + $t('inArabic')"
              class="col-lg-6"
              rules="required|min:2|max:250"
            >
              <base-input
                @keypress="isArabic"
                :name="$t('labels.title') + $t('inArabic')"
                type="text"
                :placeholder="$t('labels.title') + $t('inArabic')"
                v-model="data.ar.title"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.title') + $t('inEnglish')"
              class="col-lg-6"
              rules="required|min:2|max:250"
            >
              <base-input
                @keypress="isEnglish"
                :name="$t('labels.title') + $t('inEnglish')"
                type="text"
                :placeholder="$t('labels.title') + $t('inEnglish')"
                v-model="data.en.title"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: title -->

            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.base_fare')"
              class="col-lg-6"
              rules="required|min_value:0"
            >
              <base-input
                :name="$t('labels.base_fare')"
                type="number"
                :placeholder="$t('labels.base_fare')"
                v-model="data.base_fare"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: base_fare -->
            <!-- Start:: minimum_fare -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.minimum_fare')"
              class="col-lg-6"
              rules="required|min_value:0"
            >
              <base-input
                :name="$t('labels.minimum_fare')"
                type="number"
                :placeholder="$t('labels.minimum_fare')"
                v-model="data.minimum_fare"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: minimum_fare -->
            <!-- Start:: price_per_kilo -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.price_per_kilo')"
              class="col-lg-6"
              rules="required|min_value:0"
            >
              <base-input
                :name="$t('labels.price_per_kilo')"
                type="number"
                :placeholder="$t('labels.price_per_kilo')"
                v-model="data.price_per_kilo"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: price_per_kilo -->
            <!-- Start:: price_per_minute -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.price_per_minute')"
              class="col-lg-6"
              rules="required|min_value:0"
            >
              <base-input
                :name="$t('labels.price_per_minute')"
                type="number"
                :placeholder="$t('labels.price_per_minute')"
                v-model="data.price_per_minute"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: price_per_minute -->
            <!-- Start:: priority -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.priority')"
              class="col-lg-6"
              rules="required|min_value:0"
            >
              <base-input
                :name="$t('labels.priority')"
                type="number"
                :placeholder="$t('labels.priority')"
                v-model="data.priority"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: priority -->
          </div>
          <div class="button_wrapper center">
            <v-btn
              type="submit"
              @click="submit"
              :disabled="btnLoading"
              :loading="btnLoading"
            >
              {{ $t("submit") }}
            </v-btn>
          </div>
          <!-- <base-button type="submit" @click="submit" class="center"></base-button> -->
        </ValidationObserver>
      </div>

      <div class="form countryForm custom_card fadeIn" v-if="false">
        <form @submit.prevent="submit">
          <div class="row">
            <!-- Start:: Price For Km -->
            <base-input
              col="4"
              type="number"
              :placeholder="$t('labels.car_km_price') + $t('inEnglish')"
              :required="true"
              v-model.number="data.car_km_price"
            />
            <!-- End:: Price For Km  -->

            <!-- Start:: MinBudget -->
            <base-input
              col="4"
              type="number"
              :placeholder="$t('labels.min_budget') + $t('inEnglish')"
              :required="true"
              v-model.number="data.min_budget"
            />
            <!-- End:: MinBudget  -->

            <!-- Start:: MinBudget -->
            <base-input
              col="4"
              type="number"
              :placeholder="$t('labels.speed_avg')"
              :required="true"
              v-model.number="data.speed_avg"
            />
            <!-- End:: MinBudget  -->

            <!-- Start :: Chekcs -->
            <div class="col-lg-12 py-0">
              <div class="divider">
                <h4>الحالة</h4>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--dark"
                />
              </div>
            </div>
            <v-checkbox
              v-model="data.is_active"
              :label="$t('labels.is_active')"
            ></v-checkbox>
            <v-checkbox
              v-model="data.is_trip_active"
              :label="$t('labels.is_trip_active')"
            ></v-checkbox>
            <v-checkbox
              v-model="data.is_city_to_city_active"
              :label="$t('labels.is_city_to_city_active')"
            ></v-checkbox>
            <v-checkbox
              v-model="data.is_transfer_package_active"
              :label="$t('labels.is_package_delivering_active')"
            ></v-checkbox>
            <!-- End :: Chekcs -->
          </div>

          <base-button :loading="loading" class="center">
            {{ $t("submit") }}
          </base-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
// import MapWrite from "../../../../components/Maps/MapWrite.vue";

export default {
  // components: { MapWrite },
  props: ["id"],

  data() {
    return {
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.vehicle_packages.title"),
          disabled: false,
          href: "/vehicle_packages/show-all",
        },
        {
          text: this.$t(
            `breadcrumb.vehicle_packages.${this.id ? "edit" : "add"}`
          ),
          disabled: true,
          href: "",
        },
      ],
      // Loading
      loading: false,
      btnLoading: false,
      noData: false,
      data: {
        image: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
        ar: {
          title: null,
          desc: null,
        },
        en: {
          title: null,
          desc: null,
        },
        base_fare: null,
        minimum_fare: null,
        price_per_kilo: null,
        price_per_minute: null,
        priority: null,

        // old keys
        // car_km_price: null,
        // min_budget: null,

        // speed_avg: null,

        // is_active: false,
        // is_trip_active: false,
        // is_city_to_city_active: false,
        // is_transfer_package_active: false,
      },
    };
  },

  methods: {
    ...mapMutations({
      set_breadcrumb: "set_breadcrumb",
    }),

    inputChanged(link) {
      this.data.image.title = link;
    },

    getEditData() {
      if (this.editMode) {
        this.noData = false;
        this.loading = true;
        this.axios({
          method: "GET",
          url: `vehicle_package/${this.id}`,
        })
          .then((res) => {
            this.loading = false;
            const result = res.data.data;
            this.data.image.preview = result.image;
            this.data.ar.title = result.ar?.title;
            this.data.en.title = result.en?.title;
            this.data.base_fare = result.base_fare;
            this.data.minimum_fare = result.minimum_fare;
            this.data.price_per_kilo = result.price_per_kilo;
            this.data.price_per_minute = result.price_per_minute;
            this.data.priority = result.priority;
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("error"),
              message:
                err.response?.data.message ?? err.response?.data.messages,
            });
            this.noData = true;
            this.loading = false;
          });
      }
    },

    submit() {
      this.$refs.formPackage.validate().then((d) => {
        if (d) {
          this.btnLoading = true;
          const data = new FormData();
          if (this.id) {
            if (this.data.image.image) {
              data.append("image", this.data.image.image);
            }
          } else {
            data.append("image", this.data.image.image);
          }
          data.append("ar[title]", this.data.ar.title);
          data.append("en[title]", this.data.en.title);
          data.append("base_fare", this.data.base_fare);
          data.append("minimum_fare", this.data.minimum_fare);
          data.append("price_per_kilo", this.data.price_per_kilo);
          data.append("price_per_minute", this.data.price_per_minute);
          data.append("priority", this.data.priority);
          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `vehicle_package/${this.id}`;
          } else {
            url = "vehicle_package";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");

          // =============== Start:: Edit ===============
          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.btnLoading = false;
              this.$router.push("/vehicle_packages/show-all");
            })
            .catch((err) => {
              console.log(err);

              this.btnLoading = false;
              this.$iziToast.error({
                title: this.$t("error"),
                message: err.response.data.message,
              });
            });
        } else {
          // for (const value of Object.entries(this.$refs.formPackage.errors)) {
          //   if (value[1].length) {
          //     this.$iziToast.error({
          //       timeout: 2000,
          //       message: value[1],
          //       position: "bottomRight",
          //     });
          //   }
          // }
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
      // =============== End:: Add ===============
    },
    uploadImage(event) {
      if (event) {
        this.btnLoading = true;

        this.data.image.preview = URL.createObjectURL(event);

        const data = new FormData();
        data.append("file", event);
        data.append("attachment_type", "image");
        data.append("model", "vehicle_packages");

        this.axios({
          method: "POST",
          url: "attachments",
          data: data,
        })
          .then((res) => {
            this.data.image.image = res.data.data;
            // this.$emit('inputChanged', res.data.data)
            this.btnLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("validation.error"),
              message: err.response.data.message,
            });
            // this.$emit('inputChanged', 'LinkTEST')
            this.btnLoading = false;
          });
      }
    },
  },

  computed: {
    editMode() {
      return !!this.id;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    this.set_breadcrumb({
      title: this.$t("breadcrumb.vehicle_packages.title"),
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.vehicle_packages.title"),
          disabled: false,
          href: "/vehicle_packages",
        },
        {
          text: this.$t("breadcrumb.vehicle_packages.add"),
          disabled: true,
          href: "",
        },
      ],
    });

    this.getEditData();
  },
};
</script>
