import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sidebar"},[_c('section',{staticClass:"fullScreen",attrs:{"id":"sidebar"}},[_c(VNavigationDrawer,{staticClass:"main_sidebar",attrs:{"permanent":"","expand-on-hover":false}},[_c('div',{staticClass:"top_logo"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[(_vm.current_theme == 'light')?_c('img',{staticClass:"img-fluid",attrs:{"width":"150","src":require("@/assets/media/images/logo/logo_dark.png")}}):_c('img',{staticClass:"img-fluid",attrs:{"width":"150","src":require("@/assets/media/images/logo/logo_dark.png")}})])],1)]),_c(VList,[[_c(VListItem,{attrs:{"to":"/"}},[_c(VIcon,[_vm._v("fas fa-home")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("navs.home")))])],1)],_vm._l((_vm.sidebar),function(item){return [(item.label)?_c('div',{key:item.id,staticClass:"divider_new"},[_c('span',[_vm._v(_vm._s(item.label))])]):_vm._e(),_vm._l((item.categories),function(sub_item){return [(sub_item.key == 'general')?_c(VListItem,{key:sub_item.id,attrs:{"to":`/${sub_item.permissions[0].url}`}},[_c(VIcon,[_vm._v(_vm._s(sub_item.icon))]),_c(VListItemTitle,[_vm._v(_vm._s(sub_item.title))])],1):_c(VListGroup,{key:sub_item.uuid,staticClass:"parnet",class:{
                activeDropDown: sub_item.active,
              },attrs:{"prepend-icon":sub_item.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(sub_item.title))])],1)]},proxy:true}],null,true),model:{value:(sub_item.active),callback:function ($$v) {_vm.$set(sub_item, "active", $$v)},expression:"sub_item.active"}},_vm._l((sub_item.permissions),function(child){return _c(VListItem,{key:child.id,attrs:{"to":`/${child.url}`}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(child.title))])],1)],1)}),1)]})]})],2)],1)],1),_c('aside',{ref:"smallSidebar",staticClass:"smallScreenSidebar main_sidebar"},[_c('span',{staticClass:"close",on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"fas fa-times"})]),_c('div',{staticClass:"top_logo"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[(_vm.current_theme == 'light')?_c('img',{staticClass:"img-fluid",attrs:{"width":"150","src":require("@/assets/media/images/logo/logo_dark.png")}}):_c('img',{staticClass:"img-fluid",attrs:{"width":"150","src":require("@/assets/media/images/logo/logo_dark.png")}})])],1)]),_c(VList,[_c(VListItem,{attrs:{"to":"/"}},[_c(VIcon,[_vm._v("fas fa-home")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("navs.home")))])],1),_vm._l((_vm.sidebar),function(item){return [(item.label)?_c('div',{key:item.id,staticClass:"divider_new"},[_c('span',[_vm._v(_vm._s(item.label))])]):_vm._e(),_vm._l((item.categories),function(sub_item){return [(sub_item.key == 'general')?_c(VListItem,{key:sub_item.id,attrs:{"to":`/${sub_item.permissions[0].url}`}},[_c(VIcon,[_vm._v(_vm._s(sub_item.icon))]),_c(VListItemTitle,[_vm._v(_vm._s(sub_item.title))])],1):_c(VListGroup,{key:sub_item.uuid,staticClass:"parnet",class:{
              activeDropDown: sub_item.active,
            },attrs:{"prepend-icon":sub_item.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(sub_item.title))])],1)]},proxy:true}],null,true),model:{value:(sub_item.active),callback:function ($$v) {_vm.$set(sub_item, "active", $$v)},expression:"sub_item.active"}},[_vm._l((sub_item.permissions),function(child){return _c(VListItem,{key:child.id,attrs:{"to":`/${child.url}`}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(child.title))])],1)],1)})],2)]})]})],2),(false)?_c('ul',{staticClass:"navIcons"},[(_vm.current_theme == 'dark')?_c('li',{on:{"click":function($event){return _vm.changeTheme('light')}}},[_c('i',{staticClass:"fal fa-sun"})]):_vm._e(),(_vm.current_theme == 'light')?_c('li',{on:{"click":function($event){return _vm.changeTheme('dark')}}},[_c('i',{staticClass:"fal fa-moon"})]):_vm._e(),(_vm.lang == 'ar')?_c('li',{on:{"click":function($event){return _vm.changeLang('en')}}},[_c('i',{staticClass:"fal fa-globe"})]):_vm._e(),(_vm.lang == 'en')?_c('li',{on:{"click":function($event){return _vm.changeLang('ar')}}},[_c('i',{staticClass:"fal fa-globe"})]):_vm._e()]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }